import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TextFieldGroup from "../common/TextFieldGroup";
import * as R from "ramda";
import { map } from "lodash";
import Select from "react-select-nested-group";
import CreatableSelect from "react-select-nested-group/creatable";
import toastr from "toastr";
import { Tooltip } from "reactstrap";
import MySelect from "../reports/MySelect.js";
import Modal from "react-bootstrap4-modal";
import * as moment from "moment";
import DateTime from "react-datetime";
import ReportScheduleDuck from "../../ducks/ReportSchedule";
import GeoFence from "../../ducks/GeoFence";
import RouteFence from "../../ducks/RouteFence";
import Groups from "../../ducks/Groups";
import SelectComponent from "../select";


const CustomTableCell = (props) => {
  return <th style={{backgroundColor: "#F6F7F8", color: "#727272"}}>{props.children}</th>
}

const createOption = (label) => ({
  label,
  value: label,
});

const components = {
  DropdownIndicator: null,
};

class ReportSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      _id: null,
      isEditMode: false,
      emailList: [],
      vehicleOptions: [],
      selectedVehicleList: [],
      email: "",
      interval: "",
      reportTypes: [],
      selectedGroups: [],
      isAllGroupChecked: false,
      isAllVehicleChecked: false,
      speed: 80,
      reports: [],
      performanceGeoFence: [],
      performanceRouteFence: [],
      geofenceEntry: null,
      geofenceExit: null,
      dateRangePicker: {
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          startTime: moment(),
          endTime: moment(),
          key: "selection",
        },
      },
      vehicleTravelDistance: 25,
      tooltips: {},
      companyInfo: JSON.parse(localStorage.getItem("companyInfo") || "") || {},
    };

    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleAllGroupChange = this.handleAllGroupChange.bind(this);
    this.scheduleReportClickHandler = this.scheduleReportClickHandler.bind(
      this
    );
    this.handleVehicleChange = this.handleVehicleChange.bind(this);
    this.handleIntervalChange = this.handleIntervalChange.bind(this);
    this.handleReportTypeChange = this.handleReportTypeChange.bind(this);
    this.editReportSchedule = this.editReportSchedule.bind(this);
    this.deleteReportSchedule = this.deleteReportSchedule.bind(this);
    this.deleteSchedule = this.deleteSchedule.bind(this);
    this.handleAllVehicleChange = this.handleAllVehicleChange.bind(this);
    this.handleSpeedChange = this.handleSpeedChange.bind(this);
    this.toggleToolTip = this.toggleToolTip.bind(this);
  }
  setStartTime(payload) {
    const selection = this.state.dateRangePicker.selection;
    this.setState({
      ...this.state,
      dateRangePicker: {
        selection: {
          startDate: selection.startDate,
          endDate: selection.endDate,
          startTime: payload,
          endTime: selection.endTime,
        },
      },
    });
  }
  setEndTime(payload) {
    const selection = this.state.dateRangePicker.selection;
    this.setState({
      ...this.state,
      dateRangePicker: {
        selection: {
          endDate: selection.endDate,
          startDate: selection.startDate,
          startTime: selection.startTime,
          endTime: payload,
        },
      },
    });
  }
  onInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  async scheduleReportClickHandler(e) {
    try {
      e.preventDefault();
      const groups = this.state.selectedGroups.map(R.prop("value"));
      if (!groups.length) {
        return toastr.error("Please select at least one group.");
      }
      const vehicles = this.state.selectedVehicleList.map(R.prop("value"));
      if (!vehicles.length) {
        return toastr.error("Please select at least one vehicle.");
      }
      const emails = this.state.emailList.map(R.prop("value"));
      if (!emails.length) {
        return toastr.error("Please enter at least one email.");
      }
      const { value: interval } = this.state.interval;
      if (!interval) {
        return toastr.error("Please select interval.");
      }
      const reportTypes = this.state.reportTypes.map(R.prop("value"));
      if (!reportTypes.length) {
        return toastr.error("Please select at least one report type.");
      }

      const hasGeofenceTrackReport = R.any(R.equals("GeoFenceTrackReport"))(
        reportTypes
      );
      if (
        hasGeofenceTrackReport &&
        !(this.state.geofenceEntry && this.state.geofenceExit)
      ) {
        return toastr.error("Source and Destination is required.");
      }

      const hasPerformanceReport = R.any(R.equals("PerformanceReport"))(
        reportTypes
      );
      if (
        hasPerformanceReport &&
        (!this.state.performanceGeoFence.length ||
          !this.state.performanceRouteFence.length)
      ) {
        return toastr.error(
          "Geofence and routefence are required for performance report."
        );
      }
      const hasVehicleTravelReport = R.any(R.equals("vehicleTravelReport"))(
        reportTypes
      );
      const hasVehicleTravelReportGraph = R.any(R.equals("vehicleTravelReportGraph"))(
        reportTypes
      );
      if (hasVehicleTravelReport && !this.state.vehicleTravelDistance) {
        return toastr.error("Please provide vehicel travel distance.");
      }
      const {
        _id,
        speed,
        geofenceEntry,
        geofenceExit,
        performanceGeoFence,
        performanceRouteFence,
      } = this.state;
      let scheduleReport = {
        _id,
        groups,
        vehicles,
        interval,
        emails,
        reportTypes,
        speed,
      };

      if (hasGeofenceTrackReport) {
        scheduleReport.sourceGeofenceId = geofenceEntry.value;
        scheduleReport.destinationGeofenceId = geofenceExit.value;
      }
      if (hasPerformanceReport) {
        scheduleReport.performanceGeoFence = map(
          performanceGeoFence,
          (item) => item.value
        );
        scheduleReport.performanceRouteFence = map(
          performanceRouteFence,
          (item) => item.value
        );
      }
      if (hasVehicleTravelReport || hasVehicleTravelReportGraph) {
        scheduleReport.vehicleTravelTime = JSON.stringify(
          this.state.dateRangePicker.selection
        );
        scheduleReport.vehicleTravelDistance = this.state.vehicleTravelDistance;
      }

      await this.props.actions.saveScheduleReport(scheduleReport);

      this.setState({
        _id: null,
        isEditMode: false,
        emailList: [],
        vehicleOptions: [],
        selectedVehicleList: [],
        speed: 80,
        email: "",
        interval: "",
        reportTypes: [],
        selectedGroups: [],
      });
      this.state._id
        ? toastr.success("Report scheduled successfully")
        : toastr.success("Report schedule updated");

      await this.props.actions.getReportScheduleList(scheduleReport);
    } catch (error) {
      console.log({ error });
    }
  }
  selectPerformanceGeofence = async (selectedOption) => {
    this.setState({ performanceGeoFence: selectedOption });
  };
  selectPerformanceRoutefence = async (selectedOption) => {
    this.setState({ performanceRouteFence: selectedOption });
  };
  handleEmailInputChange = (email) => {
    this.setState({ email });
  };
  handleEmailChange = (emailList, actionMeta) => {
    this.setState({ emailList });
  };
  handleEmailKeyDown = (event) => {
    const { email, emailList } = this.state;
    if (!email) return;
    switch (event.key) {
      case "Enter":
      case "Tab": {
        const emailRegex = /^([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}$/;
        if (emailRegex.test(email)) {
          this.setState({
            email: "",
            emailList: [...emailList, createOption(email)],
          });
        } else {
          toastr.error("invalid email address");
        }
        event.preventDefault();
      }
    }
  };
  handleSpeedChange = (event) => {
    let speed = R.defaultTo(80, parseInt(event.currentTarget.value));
    if (speed < 1) {
      speed = 1;
    } else if (speed > 200) {
      speed = 200;
    }
    this.setState({ speed });
  };
  selectGeofenceEntry = async (selectedOption) => {
    this.setState({ geofenceEntry: selectedOption });
  };
  selectGeofenceExit = async (selectedOption) => {
    this.setState({ geofenceExit: selectedOption });
  };
  toggleToolTip = (type, id) => {
    const { tooltips } = this.state;
    const tooltip = tooltips[`${type}-${id}`];
    tooltips[`${type}-${id}`] = !tooltip;
    this.setState({ tooltips });
  };

  async componentDidMount() {
    if (!(this.props.groupList && this.props.groupList.length > 0))
      await this.props.dispatch(Groups.creators.getSubGroups(this.state.companyInfo.groupId));

    const reportsAvailable = this.props.permissions.companyReports;

    const reports = [];

    if (reportsAvailable.indexOf("alarmReport") !== -1) {
      reports.push({ label: "Alarm Report", value: "AlarmReport" });
    }
    if (reportsAvailable.indexOf("fuelConsumedReport") !== -1) {
      reports.push({
        label: "Fuel Consumption Report",
        value: "fuelConsumptionReport",
      });
    }
    if (reportsAvailable.indexOf("tripDetailReport") !== -1) {
      reports.push({ label: "Trip Detail Report", value: "TripDetailReport" });
    }
    if (reportsAvailable.indexOf("distanceTravelledReport") !== -1) {
      reports.push({
        label: "Distance Traveled Report",
        value: "distanceReport",
      });
    }
    if (reportsAvailable.indexOf("violationReport") !== -1) {
      reports.push({ label: "Violation  Report", value: "ViolationReport" });
    }
    if (reportsAvailable.indexOf("geofenceReport") !== -1) {
      reports.push({ label: "GeoFence Report", value: "GeoFenceReport" });
    }
    if (reportsAvailable.indexOf("routefenceReport") !== -1) {
      reports.push({ label: "RouteFence Report", value: "RouteFenceReport" });
    }
    if (reportsAvailable.indexOf("performance") !== -1) {
      reports.push({ label: "Performance Report", value: "PerformanceReport" });
    }
    if (reportsAvailable.indexOf("geofenceTrackReport") !== -1) {
      reports.push({
        label: "GeoFence Track Report",
        value: "GeoFenceTrackReport",
      });
    }
    if (reportsAvailable.indexOf("vehicleNotRespondingReport") !== -1) {
      reports.push({
        label: "Vehicles Not Responding Report",
        value: "VehiclesNotRespondingReport",
      });
    }
    if (reportsAvailable.indexOf("vehiclesStatusReport") !== -1) {
      reports.push({
        label: "Vehicles Status Report",
        value: "vehicleStatusReport",
      });
    }
    if (reportsAvailable.indexOf("overSpeedingVehiclesReport") !== -1) {
      reports.push({
        label: "OverSpeeding Vehicles Report",
        value: "OverSpeedingVehiclesReport",
      });
    }
    if (reportsAvailable.indexOf("drivingScoreReport") !== -1) {
      reports.push({
        label: "Driving Score Report",
        value: "DrivingScoreReport",
      });
    }
    if (reportsAvailable.indexOf("stoppageReport") !== -1) {
      reports.push({ label: "Stoppage Report", value: "StoppageReport" });
    }
    if (reportsAvailable.indexOf("fuelConsumedReport") !== -1) {
      reports.push({
        label: "Fuel Consumed Report",
        value: "FuelConsumedReport",
      });
    }
    if (reportsAvailable.indexOf("maintenance") !== -1) {
      reports.push({ label: "Oil Change Report", value: "OilChangeReport" });
    }
    if (reportsAvailable.indexOf("maintenance") !== -1) {
      reports.push({ label: "Tyre Change Report", value: "TyreChangeReport" });
    }
    if (reportsAvailable.indexOf("vehicleTravelReport") !== -1) {
      reports.push({
        label: "Vehicle Travel Report",
        value: "vehicleTravelReport",
      });
    }
    
    if (reportsAvailable.indexOf("vehicleTravelReportGraph") !== -1) {
      reports.push({
        label: "Vehicle Travel Report Graph",
        value: "vehicleTravelReportGraph",
      });
    }
    if (reportsAvailable.indexOf("violationsGraph") !== -1) {
      reports.push({
        label: "Violations Report Graph",
        value: "violationsGraph",
      });
    }
    if (reportsAvailable.indexOf("drivingScoreGraph") !== -1) {
      reports.push({
        label: "Driving Score Report Graph",
        value: "drivingScoreGraph",
      });
    }

    if (reportsAvailable.indexOf("engrobycetrackingreport") !== -1) {
      reports.push({
        label: "Driver Behaviour Report",
        value: "engrobycetrackingreport",
      });
    }

    this.setState({ ...this.state, reports });

    await this.props.actions.getVehicelList();

    await this.props.geofenceActions.getGroupGeoFences();

    await this.props.routeFenceActions.getGroupRouteFences();

    await this.props.actions.getReportScheduleList();
  }

  handleGroupChange(selectedGroups) {
    // Fix for null value to keep current logic
    if (!selectedGroups) selectedGroups = [];

    const vehicleOptions = R.pipe(
      R.filter((vehicle) => {
        const vehicleGroupId = R.path(["group", "_id"])(vehicle);
        return R.find(R.propEq("value", vehicleGroupId))(selectedGroups);
      }),
      R.map((vehicle) => {
        const [value, label] = R.props(["_id", "plateNumber"])(vehicle);
        return { value, label };
      })
    )(this.props.vehicleList);
    this.setState({ vehicleOptions, selectedGroups });
  }

  handleVehicleChange(selectedVehicleList) {
    this.setState({ selectedVehicleList });
  }

  handleIntervalChange(interval) {
    this.setState({ interval });
  }

  handleReportTypeChange(reportTypes) {
    this.setState({ reportTypes });
  }

  editReportSchedule(reportSchedule) {
    const intervals = [
      { label: "Daily", value: "Daily" },
      { label: "Monthly", value: "Monthly" },
      { label: "Weekly", value: "Weekly" },
      { label: "Ten Days", value: "TenDays" },
    ];

    const reportTypeList = [
      { label: "Fuel Consumption Report", value: "fuelConsumptionReport" },

      { label: "Alarm Report", value: "AlarmReport" },
      { label: "Trip Detail Report", value: "TripDetailReport" },
      // { label: 'Trip Detail Report 2', value: 'TripDetailReportt' },
      { label: "Distance Traveled Report", value: "distanceReport" },
      { label: "Violation  Report", value: "ViolationReport" },
      { label: "GeoFence Report", value: "GeoFenceReport" },
      { label: "RouteFence Report", value: "RouteFenceReport" },
      { label: "Performance Report", value: "PerformanceReport" },
      {
        label: "Vehicles Not Responding Report",
        value: "VehiclesNotRespondingReport",
      },
      { label: "Vehicles Status Report", value: "vehicleStatusReport" },
      {
        label: "OverSpeeding Vehicles Report",
        value: "OverSpeedingVehiclesReport",
      },
      { label: "Driving Score Report", value: "DrivingScoreReport" },
      { label: "Stoppage Report", value: "StoppageReport" },
      { label: "Oil Change Report", value: "OilChangeReport" },
      { label: "Tyre Change Report", value: "TyreChangeReport" },
      { label: "Geofence Track Report", value: "GeoFenceTrackReport" },
      { label: "Vehicle Travel Report", value: "vehicleTravelReport" },

      { label: "Vehicle Travel Report Graph", value: "vehicleTravelReportGraph" },
      { label: "Violations Report Graph", value: "violationsGraph" },
      { label: "Driving Score Report Graph", value: "drivingScoreGraph" },
    ];

    const { vehicleList } = this.props;
    const emailList = reportSchedule.emails.map((email) => ({
      value: email,
      label: email,
    }));
    const selectedGroups = reportSchedule.groups.map((group) => ({
      value: group._id,
      label: group.name,
    }));
    const selectedVehicleList = reportSchedule.vehicles.map((vehicle) => ({
      value: vehicle._id,
      label: vehicle.plateNumber,
    }));
    const interval = R.find(R.propEq("value", reportSchedule.interval))(
      intervals
    );

    const findReportType = (reportType) =>
      R.find(R.propEq("value", reportType))(reportTypeList);
    const reportTypes = R.map(findReportType)(reportSchedule.reportTypes);

    const allGeoFence = map(this.props.geoFences, (item) => {
      return { value: item._id.toString(), label: item.name };
    });
    const allrouteFence = map(this.props.routeFences, (item) => {
      return { value: item._id.toString(), label: item.name };
    });

    const findPerformanceGeoFence = (item) =>
      R.find(R.propEq("value", item))(allGeoFence);
    const performanceGeoFence = R.map(findPerformanceGeoFence)(
      reportSchedule.performanceGeoFence
    );

    const findPerformanceRouteFence = (item) =>
      R.find(R.propEq("value", item))(allrouteFence);
    const performanceRouteFence = R.map(findPerformanceRouteFence)(
      reportSchedule.performanceRouteFence
    );

    const vehicleOptions = R.pipe(
      R.filter((vehicle) => {
        const vehicleGroupId = R.path(["group", "_id"])(vehicle);
        return R.find(R.propEq("value", vehicleGroupId))(selectedGroups);
      }),
      R.map((vehicle) => {
        const [value, label] = R.props(["_id", "plateNumber"])(vehicle);
        return { value, label };
      })
    )(vehicleList);

    this.setState({
      isEditMode: true,
      _id: reportSchedule._id,
      speed: reportSchedule.speed,
      emailList,
      vehicleOptions,
      selectedVehicleList,
      email: "",
      interval,
      reportTypes,
      selectedGroups,

      performanceGeoFence,
      performanceRouteFence,
      geofenceEntry: findPerformanceGeoFence(reportSchedule.sourceGeofenceId),
      geofenceExit: findPerformanceGeoFence(
        reportSchedule.destinationGeofenceId
      ),
      dateRangePicker: {
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          startTime: moment(),
          endTime: moment(),
          key: "selection",
        },
      },
      vehicleTravelDistance: reportSchedule.vehicleTravelDistance || 25,
    });
  }

  deleteReportSchedule = (reportSchedule) => {
    const { _id } = reportSchedule;
    this.setState({
      _id,
      dialogOpen: true,
    });
  };
  cancelDeleteOperation = () => {
    this.setState({
      _id: null,
      dialogOpen: false,
    });
  };
  deleteSchedule = async () => {
    const { _id } = this.state;
    await this.props.actions.deleteReportSchedule(_id);
    this.setState({
      _id: null,
      dialogOpen: false,
    });

    toastr.success("Report Schedule Deleted");
    await this.props.actions.getReportScheduleList();
  };
  handleAllGroupChange = async () => {
    const { isAllGroupChecked } = this.state;
    let state = { isAllGroupChecked: !isAllGroupChecked };

    if (!isAllGroupChecked) {
      const { vehicleList,groupList} = this.props;

      const selectedGroups = R.pipe(
        R.map((v) => ({ value: v._id, label: v.name })),
        R.uniqBy(R.prop("value"))
      )(R.clone(groupList));

      const vehicleOptions = R.pipe(
        R.filter((vehicle) => {
          const vehicleGroupId = R.path(["group", "_id"])(vehicle);
          return R.find(R.propEq("value", vehicleGroupId))(selectedGroups);
        }),
        R.map((vehicle) => {
          const [value, label] = R.props(["_id", "plateNumber"])(vehicle);
          return { value, label };
        })
      )(vehicleList);

      state.vehicleOptions = vehicleOptions;
      state.selectedGroups = selectedGroups;
    } else {
      state.vehicleOptions = [];
      state.selectedGroups = [];
    }
    this.setState({ ...state });
  };

  handleAllVehicleChange = async () => {
    const { isAllVehicleChecked, vehicleOptions } = this.state;
    let state = { isAllVehicleChecked: !isAllVehicleChecked };
    if (!isAllVehicleChecked) {
      state.selectedVehicleList = vehicleOptions;
    }
    this.setState({ ...state });
  };

  render() {
    const { classes, reportScheduleList = [] } = this.props;
    const {
      emailList,
      email,
      vehicleOptions,
      selectedVehicleList,
      reportTypes,
      selectedGroups,
      interval,
      isEditMode,
      isAllGroupChecked,
      isAllVehicleChecked,
      reports,
    } = this.state;

    let hasGeofenceTrackReport = false;
    let hasPerformanceReport = false;
    let hasOverSpeedingReport = false;
    let hasVehicleTravelReport = false;
    let hasVehicleTravelReportGraph = false;

    if (reportTypes && reportTypes.length) {
      hasGeofenceTrackReport = R.any(R.propEq("value", "GeoFenceTrackReport"))(
        reportTypes
      );
      hasPerformanceReport = R.any(R.propEq("value", "PerformanceReport"))(
        reportTypes
      );
      hasOverSpeedingReport = R.any(
        R.propEq("value", "OverSpeedingVehiclesReport")
      )(reportTypes);
      hasVehicleTravelReport = R.any(R.propEq("value", "vehicleTravelReport"))(
        reportTypes
      );
      hasVehicleTravelReportGraph = R.any(R.propEq("value", "vehicleTravelReportGraph"))(
        reportTypes
      );
    }
    
    const reportScheduleListData = reportScheduleList.map(
      (reportSchedule, index) => {
        let vehicles = reportSchedule.vehicles
          .map(R.prop("plateNumber"))
          .join(",");
        vehicles =
          vehicles.length > 20
            ? vehicles.substr(0, 20).concat("...")
            : vehicles;
        let emails = reportSchedule.emails.join(",");
        emails =
          emails.length > 20 ? emails.substr(0, 20).concat("...") : emails;
        let groups = reportSchedule.groups.map(R.prop("name")).join(",");
        groups =
          groups.length > 20 ? groups.substr(0, 20).concat("...") : groups;
        let reportTypes = reportSchedule.reportTypes.join(",");
        reportTypes =
          reportTypes.length > 20
            ? reportTypes.substr(0, 20).concat("...")
            : reportTypes;
        const { interval, speed } = reportSchedule;
        return (
          <tr key={index}>
            <td align="left">
              <span id={`table-row-group-${index}`}>{groups}</span>
            </td>
            <td align="left">
              <span id={`table-row-vehicle-${index}`}>{vehicles}</span>
            </td>
            <td align="left">{interval}</td>
            <td align="left">{speed}</td>
            <td align="left">
              <span id={`table-row-email-${index}`}>{emails}</span>
            </td>
            <td align="left">
              <span id={`table-row-type-${index}`}>{reportTypes}</span>
            </td>
            <td align="left">
              <i className="fa fa-pencil" aria-hidden="true" style={{color: "#3F51B5"}} onClick={() => this.editReportSchedule(reportSchedule)}></i>
            </td>
            <td align="left">
              <i className="fa fa-trash" aria-hidden="true" style={{color: "#3F51B5"}} onClick={() => this.deleteReportSchedule(reportSchedule)}></i>
            </td>
          </tr>
        );
      }
    );
    const hasNoData = R.isEmpty(reportScheduleList);
    return (
      <div>
        <form autoComplete="off" className="root">
          <div className="row">
            <div className="col-md-4 col-sm-6 extraPadding">
              <span>
                <SelectComponent
                  field="form-field-name"
                  placeholder="Select Group"
                  multi={true}
                  disabled={isEditMode || isAllGroupChecked}
                  preSelected={selectedGroups}
                  optionKeys={{ label: "name", value: "_id" }}
                  options={this.props.groupList}
                  onChange={this.handleGroupChange}
                />
              </span>
            </div>
            <div className="extraPadding col-md-2 col-sm-2">
              <div>
                <input
                  type="checkbox"
                  className="material-checkbox"
                  checked={this.state.isAllGroupChecked}
                  onChange={this.handleAllGroupChange}
                />
                <label>All Groups</label>
              </div>
            </div>

            <div className="col-sm-6 col-md-4 extraPadding">
              <span>
                <Select
                  placeholder="Select Vehicles"
                  isMulti
                  value={selectedVehicleList}
                  options={vehicleOptions}
                  onChange={this.handleVehicleChange}
                  isDisabled={isEditMode || isAllVehicleChecked}
                />
              </span>
            </div>
            <div className="col-md-2 col-sm-2 extraPadding">
              <div>
                <input
                  type="checkbox"
                  className="material-checkbox"
                  checked={this.state.isAllVehicleChecked}
                  onChange={this.handleAllVehicleChange}
                />
                <label>All Vehicles</label>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 extraPadding">
              <span>
                <Select
                  placeholder="Schedule Interval"
                  onChange={this.handleIntervalChange}
                  value={interval}
                  options={[
                    { label: "Daily", value: "Daily" },
                    { label: "Monthly", value: "Monthly" },
                    { label: "Weekly", value: "Weekly" },
                    { label: "Ten Days", value: "TenDays" },
                  ]}
                />
              </span>
            </div>
            <div className="col-md-4 col-sm-6 extraPadding">
              <span>
                <CreatableSelect
                  components={components}
                  inputValue={email}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={this.handleEmailChange}
                  onInputChange={this.handleEmailInputChange}
                  onKeyDown={this.handleEmailKeyDown}
                  placeholder="Email Addresses"
                  value={emailList}
                />
              </span>
            </div>
            <div item sm={6} md={4} className="col-md-4 col-sm-6 extraPadding">
              <span>
                <Select
                  placeholder="Report Types"
                  isMulti
                  onChange={this.handleReportTypeChange}
                  value={reportTypes}
                  options={reports}
                />
              </span>
            </div>

            {hasGeofenceTrackReport ? (
              <>
                <div className="col-md-4 col-sm-6 extraPadding">
                  <Select
                    name="form-field-name"
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Select Source Geofence"
                    onChange={this.selectGeofenceEntry}
                    value={this.state.geofenceEntry}
                    options={this.props.geoFences.map((geofence) => {
                      return { value: geofence._id, label: geofence.name };
                    })}
                  />
                </div>
                <div className="col-md-4 col-sm-6 extraPadding">
                  <Select
                    name="form-field-name"
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Select Destination Geofence"
                    onChange={this.selectGeofenceExit}
                    value={this.state.geofenceExit}
                    options={this.props.geoFences.map((geofence) => {
                      return { value: geofence._id, label: geofence.name };
                    })}
                  />
                </div>
              </>
            ) : (
              ""
            )}
            {hasPerformanceReport ? (
              <>
                <div className="col-sm-6 col-md-4 extraPadding">
                  <MySelect
                    name="form-field-name"
                    isClearable={true}
                    isSearchable={true}
                    isMulti
                    allowSelectAll={this.props.geoFences.length > 0}
                    placeholder="Select Geofence for performance report"
                    onChange={this.selectPerformanceGeofence}
                    value={this.state.performanceGeoFence}
                    options={this.props.geoFences.map((geofence) => {
                      return { value: geofence._id, label: geofence.name };
                    })}
                  />
                </div>
                <div className="col-sm-6 col-md-4 extraPadding">
                  <MySelect
                    name="form-field-name"
                    isClearable={true}
                    isSearchable={true}
                    isMulti
                    allowSelectAll={this.props.routeFences.length > 0}
                    placeholder="Select RouteFence for performance report"
                    onChange={this.selectPerformanceRoutefence}
                    value={this.state.performanceRouteFence}
                    options={this.props.routeFences.map((routefence) => {
                      return { value: routefence._id, label: routefence.name };
                    })}
                  />
                </div>
              </>
            ) : (
              ""
            )}
            {hasVehicleTravelReportGraph ? (
              <>
                <div className="col-sm-6 col-md-4 extraPadding">
                  <div>
                    <label>Start Time</label>
                    <DateTime
                      dateFormat={false}
                      value={this.state.dateRangePicker.selection.startTime}
                      timeFormat="HH:mm"
                      onChange={this.setStartTime.bind(this)}
                    />
                    {/* {errorDate ? <div >
                            <label style={{ color: 'red' }}>{errorDate}</label></div>
                            : ''} */}
                    <label>End Time</label>
                    <DateTime
                      dateFormat={false}
                      value={this.state.dateRangePicker.selection.endTime}
                      timeFormat="HH:mm"
                      onChange={this.setEndTime.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 extraPadding">
                  <div>
                    <TextFieldGroup
                      onChange={this.onInputChange.bind(this)}
                      value={this.state.vehicleTravelDistance}
                      type={"Number"}
                      field={"vehicleTravelDistance"}
                      label={"Min distance travelled (KM)"}
                      placeholder={"Min distance travelled"}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {hasVehicleTravelReport ? (
              <>
                <div className="col-sm-6 col-md-4 extraPadding">
                  <div>
                    <label>Start Time</label>
                    <DateTime
                      dateFormat={false}
                      value={this.state.dateRangePicker.selection.startTime}
                      timeFormat="HH:mm"
                      onChange={this.setStartTime.bind(this)}
                    />
                    {/* {errorDate ? <div >
                            <label style={{ color: 'red' }}>{errorDate}</label></div>
                            : ''} */}
                    <label>End Time</label>
                    <DateTime
                      dateFormat={false}
                      value={this.state.dateRangePicker.selection.endTime}
                      timeFormat="HH:mm"
                      onChange={this.setEndTime.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 extraPadding">
                  <div>
                    <TextFieldGroup
                      onChange={this.onInputChange.bind(this)}
                      value={this.state.vehicleTravelDistance}
                      type={"Number"}
                      field={"vehicleTravelDistance"}
                      label={"Min distance travelled (KM)"}
                      placeholder={"Min distance travelled"}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {hasOverSpeedingReport ? (
              <div className="col-sm-6 col-md-4">
                <label htmlFor="speed-input">Speed</label>
                <input
                  id="speed-input"
                  value={this.state.speed}
                  onChange={this.handleSpeedChange}
                  type="number"
                  className="textField"
                  placeholder="Speed Limit"
                />
              </div>
            ) : (
              ""
            )}

            <div className="col-sm-12 col-md-12 extraPadding">
              <span>
                <button
                  className="btn auxo-primary-btn margin-zero"
                  onClick={this.scheduleReportClickHandler}
                >
                  {isEditMode ? "Update" : "Save"}
                </button>
              </span>
            </div>
            <div className="col-sm-12">
              <div className="paper">
                <h4>Scheduled Reports</h4>
                <table className="table-auxo">
                  <thead>
                    <tr>
                      <CustomTableCell align="left">Groups</CustomTableCell>
                      <CustomTableCell align="left">Vehicles</CustomTableCell>
                      <CustomTableCell align="left">Interval</CustomTableCell>
                      <CustomTableCell align="left">Speed</CustomTableCell>
                      <CustomTableCell align="left">Emails</CustomTableCell>
                      <CustomTableCell align="left">
                        Report Types
                      </CustomTableCell>
                      <CustomTableCell align="left">Edit</CustomTableCell>
                      <CustomTableCell align="left">Delete</CustomTableCell>
                    </tr>
                  </thead>

                  <tbody>{reportScheduleListData}</tbody>
                </table>

                {hasNoData ? (
                  <h5 style={{ textAlign: "center" }}>
                    No scheduled report added yet
                  </h5>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </form>
        <Modal
          visible={this.state.dialogOpen}
          onClickBackdrop={this.cancelDeleteOperation}
          dialogClassName="delete-modal-dialog"
        >
          <div>
            <button
              type="button"
              className="close close-x"
              aria-label="Close"
              onClick={this.cancelDeleteOperation.bind(this)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-heading">
            <h5 className="car-and-device-assig">
              {"Delete Report Schedule?"}
            </h5>
          </div>
          <div className="modal-body">
            <h6>Do you want to delete this report schedule ?</h6>
          </div>
          <div className="modal-footer model-btn">
            <button
              onClick={this.cancelDeleteOperation}
              className="btn auxo-primary-btn"
            >
              No
            </button>
            <button
              onClick={this.deleteSchedule}
              className="btn auxo-primary-btn"
            >
              Yes
            </button>
          </div>
        </Modal>
        {reportScheduleList &&
          reportScheduleList.map((reportSchedule, index) => {
            const vehicles = reportSchedule.vehicles
              .map(R.prop("plateNumber"))
              .join(",");
            const emails = reportSchedule.emails.join(",");
            const groups = reportSchedule.groups.map(R.prop("name")).join(",");
            const reportTypes = reportSchedule.reportTypes.join(",");

            return (
              <div key={index}>
                <Tooltip
                  placement="right"
                  isOpen={this.state.tooltips[`group-${index}`]}
                  target={`table-row-group-${index}`}
                  toggle={() => this.toggleToolTip("group", index)}
                >
                  {groups}
                </Tooltip>
                <Tooltip
                  placement="right"
                  isOpen={this.state.tooltips[`vehicle-${index}`]}
                  target={`table-row-vehicle-${index}`}
                  toggle={() => this.toggleToolTip("vehicle", index)}
                >
                  {vehicles}
                </Tooltip>
                <Tooltip
                  placement="right"
                  isOpen={this.state.tooltips[`email-${index}`]}
                  target={`table-row-email-${index}`}
                  toggle={() => this.toggleToolTip("email", index)}
                >
                  {emails}
                </Tooltip>
                <Tooltip
                  placement="right"
                  isOpen={this.state.tooltips[`type-${index}`]}
                  target={`table-row-type-${index}`}
                  toggle={() => this.toggleToolTip("type", index)}
                >
                  {reportTypes}
                </Tooltip>
              </div>
            );
          })}
      </div>
    );
  }
}

// const mapStateToProps = R.pipe(R.prop('reportSchedule'), R.pick(['vehicleList', 'reportScheduleList']))
const mapStateToProps = (state) => ({
  vehicleList: state.reportSchedule.vehicleList,
  reportScheduleList: state.reportSchedule.reportScheduleList,
  geoFences: state.geoFence.geoFences,
  routeFences: state.routeFence.routeFences,
  groupList: state.groups.subGroups,
  permissions: state.permissions,
});

const curriedBindAction = R.curry(bindActionCreators);
const mapDispatchToProps = R.applySpec({
  actions: curriedBindAction(ReportScheduleDuck.creators),
  routeFenceActions: curriedBindAction(RouteFence.creators),
  geofenceActions: curriedBindAction(GeoFence.creators),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSchedule);
