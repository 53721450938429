import React from "react";
import Select from "react-select-nested-group";
import SelectComponent from "../select";
import { Popover, PopoverBody } from 'reactstrap';
import { DateRangePicker } from 'react-date-range';
import calenderIcon from '../../assets/img/calender.png';
import { Card, CardHeader } from "reactstrap";
import './index.css'
import { subDays } from 'date-fns';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { map } from "lodash";
import ragscore from "../../ducks/ragscore";
import { Spinner } from '../common/Spinner/index';
import { PieChart, Pie, Cell } from 'recharts';



const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RagScore = (props) => {
  const chartCenterX = 155;
  const chartCenterY = 150;
  const chartRadius = 60;
  const labelOffset = 16; // Adjust this value for the desired space
  const [popoverOpen, setPopoverOpen] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState([])
  const [selectedGroup, setSelectedGroup] = React.useState([])
  const [searchVehicleValues, setSearchVehicleValues] = React.useState([])
  const [showSD , setShowSD] = React.useState(false)
  const [dateRangePicker, setDateRange] = React.useState({
    selection: {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: 'selection',
    },
  },)
  const handleRangeChange = async (payload) => {
    setDateRange({ ...payload })
  }

  const toggle = () => {
    setPopoverOpen(!popoverOpen)
  };
  const filterVehicle = async (value) => {
    setSearchValue({ ...value });
  };

  const fetchOptions = async () => {
    await props.ragscoreAction.getThings();
    await props.ragscoreAction.getSubGroups();
  }

  const selectGroup = (selectedGroup) => {
    setSelectedGroup(selectedGroup)
  }

  const fetchAnalytics = async () => {
    if (searchValue.value) {
      await props.ragscoreAction.getViolations(searchValue.value, dateRangePicker)
    }
    else if (selectedGroup.length > 0) {
      await props.ragscoreAction.getViolations(selectedGroup, dateRangePicker)
    }
  }

  React.useEffect(() => {
    if (searchValue?.value) {
      setSelectedGroup([])
    }
  }, [searchValue]);

  React.useEffect(() => {
    if (selectedGroup?.length > 0) {
      setSearchValue([])
    }
  }, [selectedGroup]);

  React.useEffect(() => {
    fetchOptions()
  }, []);

  React.useEffect(() => {
    setSearchVehicleValues(map(props?.things, (item) => ({
      value: item._id,
      label: item.plateNumber || item.imei.substr(-8),
      item: item,
    })))
  }, [props.things]);
  return (
    <div>
      <div className="row">

        <div className="col-md-3">
          <Select
            name="form-field-name"
            isSearchable={true}
            placeholder="Search Vehicle"
            onChange={filterVehicle}
            value={searchValue}
            options={searchVehicleValues}
          />
        </div>
        <div className="col-md-3">
          <SelectComponent
            field="form-field-name"
            placeholder="Select Group"
            preSelected={selectedGroup}
            multi={true}
            optionKeys={{ label: "name", value: "_id" }}
            options={props.groupList}
            onChange={selectGroup}
          />

        </div>
        <div className="col-xs-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div className="float-right">
            <a onClick={toggle} id="Popover1">
              <img className="rag-picker" src={calenderIcon} alt="" />
            </a>
            <Popover placement="bottom" isOpen={popoverOpen} toggle={toggle} target="Popover1">
              <PopoverBody>
                <div>
                  <DateRangePicker
                    onChange={handleRangeChange}
                    showSelectionPreview={false}
                    moveRangeOnFirstSelection={false}
                    className={'PreviewArea'}
                    months={1}
                    ranges={[dateRangePicker.selection]}
                    direction="horizontal"
                    maxDate={new Date()}
                  />
                </div>
              </PopoverBody>
            </Popover>
            <button onClick={fetchAnalytics} className='btn rag-primary-btn rag-score-filter-btn' >Filter
            </button>
          </div>
        </div>
      </div>
      {props.isLoading === true && (
        <Spinner />
      )}
      {searchValue?.length === 0 && selectedGroup?.length === 0 && (
        <div className="vehicle-plate-Number-model">
          <p className="vehicle-plate-number">No Vehicle Selected</p>
          <p className="vehicle-model">{'N/A'}</p>
        </div>
      )}
      {props.isLoading === false && props.analytics !== null && (
        <>
          {props.analytics.length === 1 && (
            <>
              <div className="row">
                <Card className={"rag-card-content"}>
                  <CardHeader className={"f-bold"}>
                    Parameter Score
                  </CardHeader>
                  <div className="rag-pie-group">
                    {props.analytics[0].map((item, index) => (
                      <PieChart key={index} width={280} height={280}>
                        <Pie
                          data={item?.value === 0 ? [{ value: 1 }] : [item]}
                          cx={chartCenterX}
                          cy={chartCenterY}
                          labelLine={false}
                          label={({ cx, cy, value }) => (
                            <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#000">
                              {item?.value === 0 ? 0 : value}
                            </text>
                          )}
                          innerRadius={46}
                          outerRadius={chartRadius}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {props.analytics.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        {/* Display the name at the bottom with some space */}
                        <text x={chartCenterX} y={chartCenterY + chartRadius + labelOffset} dy={16} textAnchor="middle" fill="#000">
                          {item.name}
                        </text>
                      </PieChart>
                    ))}
                  </div>
                </Card>
              </div>
              <div className="row">
                <Card className={"rag-card-content"}>
                  <CardHeader className={"f-bold"}>
                    RAG Color and Action Plan
                  </CardHeader>
                  <div className="row rag-status-card">
                    {props.analytics[0][3].value >= 0 && props.analytics[0][3].value <= 5 && (
                      <>
                        <div className="rag-status-bar-positive">
                        </div>
                        <div>
                          <text className="rag-status-text">Positive Recognition</text>
                        </div>
                      </>
                    )}
                    {props.analytics[0][3].value >5 && props.analytics[0][3].value <= 10 && (
                      <>
                        <div className="rag-status-bar-intervention">
                        </div>
                        <div>
                          <text className="rag-status-text">Intervention based on the top loss</text>
                        </div>
                      </>
                    )}
                    {props.analytics[0][3].value > 10 && (
                      <>
                        <div className="rag-status-bar-warning">
                        </div>
                        <div>
                          <text className="rag-status-text">a. Formal warning with immediate feedback to be shared in
                            same month
                            b. Stringent action to be taken against the individual who
                            rated in red zone for two consecutive months.</text>
                        </div>
                      </>
                    )}
                  </div>
                </Card>
              </div>
            </>
          )}
          {props.analytics.length > 1 && (
            <>
              {props.accumulativeAnalytics !== null && (
                <>
                  <div style={{justifyContent:'flex-end' , alignItems:'baseline'}} className="row">
                    <label style={{marginRight:'23px'}}>Show SD</label>
                    <div style={{right:'20px' , top:'5px'}} className="toggle-switch">
                      <input
                        type="checkbox"
                        className="toggle-switch-checkbox"
                        id={`${"sd"}-privateMode`}
                        checked={showSD}
                        onChange={(e) => {
                          setShowSD(!showSD)
                          console.log("showSD" , e)
                          
                        }}
                      />
                      <label
                        className="toggle-switch-label"
                        htmlFor={`${"sd"}-privateMode`}
                      >
                        <span className="toggle-switch-inner" />
                        <span className="toggle-switch-switch" />
                      </label>
                    </div>
                  </div>
                  <div className="row ">
                    <Card className={"rag-card-content"}>
                      <CardHeader className={"f-bold"}>
                        Total Parameter Score
                      </CardHeader>
                      <div className="rag-pie-group">
                        {props.accumulativeAnalytics.map((item) => (
                          <>
                           {item.name === 'SD' && showSD === false ? (
                            null
                           ): <PieChart width={280} height={280}>
                           <Pie
                             data={item?.value === 0 ? [{ value: 1 }] : [item]}
                             cx={chartCenterX}
                             cy={chartCenterY}
                             labelLine={false}
                             label={({ cx, cy, value }) => (
                               <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#000">
                                 {item?.value === 0 ? 0 : value}
                               </text>

                             )}
                             innerRadius={46}
                             outerRadius={chartRadius}
                             fill="#8884d8"
                             dataKey="value"
                           >
                             {props.accumulativeAnalytics.map((entry, index) => (
                               <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                             ))}
                           </Pie>
                           <text x={item.name === 'Total RAG Score(Population)' ? 160 : chartCenterX} y={chartCenterY + chartRadius + labelOffset} dy={16} textAnchor="middle" fill="#000">
                             {item.name}
                           </text>
                         </PieChart>}
                            
                          </>
                        ))}
                      </div>
                    </Card>
                  </div>
                  <div className="row">
                    <Card className={"rag-card-content"}>
                      <CardHeader className={"f-bold"}>
                        RAG Color and Action Plan
                      </CardHeader>
                      <div className="row rag-status-card">
                        {props.accumulativeAnalytics[3].value >= 0 && props.accumulativeAnalytics[3].value <= 5 && (
                          <>
                            <div className="rag-status-bar-positive">
                            </div>
                            <div>
                              <text className="rag-status-text">Positive Recognition</text>
                            </div>
                          </>
                        )}
                        {props.accumulativeAnalytics[3].value >= 6 && props.accumulativeAnalytics[3].value <= 10 && (
                          <>
                            <div className="rag-status-bar-intervention">
                            </div>
                            <div>
                              <text className="rag-status-text">Intervention based on the top loss</text>
                            </div>
                          </>
                        )}
                        {props.accumulativeAnalytics[3].value > 10 && (
                          <>
                            <div className="rag-status-bar-warning">
                            </div>
                            <div className="rag-status-text col-md-3">a. Formal warning with immediate feedback to be shared in
                              same month
                              b. Stringent action to be taken against the individual who
                              rated in red zone for two consecutive months.</div>
                          </>
                        )}
                      </div>
                    </Card>
                  </div>
                </>
              )}
              {props.analytics.map((item, index) => (
                <React.Fragment key={index}>
                  <div className="row">
                    <Card className={"rag-card-content"}>
                      <CardHeader className={"f-bold"}>
                        {item[0]}
                      </CardHeader>
                      <div className="rag-pie-group">
                        {item.map((nestedItem, index) => (
                          <React.Fragment>
                            {index >= 1 && (
                              <PieChart width={280} height={280}>
                                <Pie
                                  data={nestedItem?.value === 0 ? [{ value: 1 }] : [nestedItem]}
                                  cx={chartCenterX}
                                  cy={chartCenterY}
                                  labelLine={false}
                                  label={({ cx, cy, value }) => (
                                    <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#000">
                                      {nestedItem?.value === 0 ? 0 : value}
                                    </text>
                                  )}
                                  innerRadius={46}
                                  outerRadius={chartRadius}
                                  fill="#8884d8"
                                  dataKey="value"
                                >
                                  {props.analytics.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                  ))}
                                </Pie>
                                <text x={chartCenterX} y={chartCenterY + chartRadius + labelOffset} dy={16} textAnchor="middle" fill="#000">
                                  {nestedItem.name}
                                </text>
                              </PieChart>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    </Card>
                  </div>
                  <div className="row">
                    <Card className={"rag-card-content"}>
                      <CardHeader className={"f-bold"}>
                        RAG Color and Action Plan
                      </CardHeader>
                      <div className="row rag-status-card">
                        {item[4].value >= 0 && item[4].value <= 5 && (
                          <>
                            <div className="rag-status-bar-positive">
                            </div>
                            <div>
                              <text className="rag-status-text">Positive Recognition</text>
                            </div>
                          </>
                        )}
                        {item[4].value >5 && item[4].value <= 10 && (
                          <>
                            <div className="rag-status-bar-intervention">
                            </div>
                            <div>
                              <text className="rag-status-text">Intervention based on the top loss</text>
                            </div>
                          </>
                        )}
                        {item[4].value > 10 && (
                          <>
                            <div className="rag-status-bar-warning">
                            </div>
                            <div className="rag-status-text col-md-3">a. Formal warning with immediate feedback to be shared in
                              same month
                              b. Stringent action to be taken against the individual who
                              rated in red zone for two consecutive months.</div>
                          </>
                        )}
                      </div>
                    </Card>
                  </div>
                </React.Fragment>
              ))}
            </>
          )}

        </>
      )}

    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    analytics: state.ragscore.analytics,
    things: state.ragscore.things,
    groupList: state.ragscore.groupList,
    accumulativeAnalytics: state.ragscore.accumulativeAnalytics,
    isLoading: state.ragscore.isLoading
  };
};

const mapDispatchToProps = (dispatch) => ({
  ragscoreAction: bindActionCreators(ragscore.creators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RagScore)