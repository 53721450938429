import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Reports from "../../ducks/Reports";
import { Spinner } from "../common/Spinner"
import moment from "moment";
const DownloadReports = (props) => {
    const [state, setState] = useState({
        loading: false
    })
    useEffect(() => {
        (async() => {
            setState({
            ...state,
            loading: true
            })
            await props.reportActions.fetchLogs()
            setState({
                ...state,
                loading: false
            })
        })();
    }, [])
    return state.loading ? <Spinner /> : <div className="row">
        <div className="col-sm-12">
        <h1>Report Logs</h1>
        </div>
        <div className="col-sm-12 margin-top-20">
            <table className="table-auxo">
                <thead>
                    <th>Report Type</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Requested At</th>
                    <th>Status</th>
                    <th>Download Link</th>
                </thead>
                <tbody>
                    {
                        props?.logs?.map((row, index) => {
                            return (
                                <tr key={index.toString()}>
                                    <td>{row.reportType}</td>
                                    <td>{row.dateFrom} {row.startTime}</td>
                                    <td>{row.dateTo} {row.endTime}</td>
                                    <td>{moment(row.createdAt).format("DD-MMM-YYYY h:mm a")}</td>
                                    <td>{row.isCompleted ? 'Completed': 'Pending'}</td>
                                    <td>{row.url ? <a href={row.url} target="_blank" rel="noreferrer">Download</a>: 'N/A'}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
        </div>
}

const mapStateToProps = (state) => ({
    logs: state.Reports.logs
})

const mapDispatchToProps = (dispatch) => ({
    reportActions: bindActionCreators(Reports.creators, dispatch)
})

export default connect(mapStateToProps,mapDispatchToProps)(DownloadReports);