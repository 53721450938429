import Duck from 'extensible-duck'
import { reportsEndPoints } from "../api/endpoints";
import moment from "moment";
import UserAuthentication from "./UserAuthentication";
import axios from "axios";
import toastr from "toastr";

const authenticationActions = UserAuthentication.creators;

export default new Duck({
  namespace: 'AuxoReports',
  store: 'reports',
  types: [
    'IS_LOADING',
    'LOGS',
    'GEO_FENCE_SUMMARY',
    'MILEAGE_REPORT',
    'FUEL_REPORT_VIEW',
    'GEO_FENCE_SUMMARY_MAP_VIEW'
  ],
  initialState: {
    isLoading: false,
    logs: [],
    mileageData: [],
    geofenceSummary: [],
    modal: false,
    eventsCount : 0,
    rowsCount : 0,
    rowsCountActivity : 0,
    sizePerPage : 10,
    sizePerPageFuel : 10,
    sizePerPageFuelActivity : 10,
    page :0,
    pageFuel :0,
    pageFuelActivity :0,
    summaryState : {},
    mileageTableState: {},
    fuelRowsGraph : {},
    fuelRowsActivityGraph : {},
    fuelState : {},
    fuelStateActivity : {},
    summaryMap:{},
    fuelRows : {},
    fuelRowsActivity : {},
  },
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.IS_LOADING:
        return {
          ...state,
          isLoading: action.isLoading
        };
      case "GEO_FENCE_SUMMARY":
        return {
          ...state,
          geofenceSummary: action.payload.data,
          eventsCount: action.payload.eventsCount,
          sizePerPage : action.payload.sizePerPage,
          page : action.payload.page,
          summaryState : action.payload.state
        }
      case "MILEAGE_REPORT":
        return {
          ...state,
          mileageData: action.payload.data,
          totalDistance: action.payload.totalDistance,
          eventsCount: action.payload.eventsCount,
          sizePerPage : action.payload.sizePerPage,
          page : action.payload.page,
          mileageTableState : action.payload.state
        }
      case "FUEL_REPORT_VIEW":
        return {
          ...state,
          sizePerPageFuel : action.payload.sizePerPage,
          pageFuel : action.payload.page,
          rowsCount : action.payload.rowsCount,
          fuelState : action.payload.state,
          fuelRows : action.payload.data,
          fuelRowsGraph : action.payload.graphData
        }
      case "FUEL_ACTIVITY_REPORT_VIEW" : 
        return {
          ...state,
          sizePerPageFuelActivity : action.payload.sizePerPage,
          pageFuelActivity : action.payload.page,
          rowsCountActivity : action.payload.rowsCount,
          fuelStateActivity : action.payload.state,
          fuelRowsActivity : action.payload.data,
          fuelRowsActivityGraph : action.payload.graphData
        }
      case "GEO_FENCE_SUMMARY_MAP_VIEW":
        return {
          ...state,
          summaryMap : action.payload
        }
      case "LOGS":
        return {
          ...state,
          logs: action.payload
        }
      case "MODAL":
        return {
          ...state,
          modal: !state.modal
        }

      default:
        return state;
    }
  },
  selectors: {
    root: state => state
  },
  creators: (duck) => ({
    exportReport: (state) => async (dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) dispatch(authenticationActions.logoutUser());
        try {
        dispatch({ type: duck.types.IS_LOADING, isLoading: true });
        if (state.reportType === 'tripReport') {
          const response = await axios.post(reportsEndPoints.tripReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        }
        if (state.reportType === 'geofenceTrackReport') {
          const response = await axios.post(reportsEndPoints.geofenceTrackReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } 
        else if (state.reportType === 'eventReport') {
          const response = await axios.post(reportsEndPoints.violationReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } 
        else if (state.reportType === 'eventGraphReport') {
          const response = await axios.post(reportsEndPoints.violationsGraph(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } 
        else if (state.reportType === 'geofenceReport') {
          const response = await axios.post(reportsEndPoints.geofenceReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } 
        else if (state.reportType === 'geofenceSummaryReport') {
          const response = await axios.post(reportsEndPoints.geofenceSummaryReport(),state);
          return response.data;
          // if(response.data.status === "success") {
          //   dispatch({type: "MODAL"});
          // }
        }
        else if (state.reportType === 'mileageReport') {
          const response = await axios.post(reportsEndPoints.mileageReportExport(),state);
          return response.data;
        }
        else if (state.reportType === 'newfuelAverage') {
          const response = await axios.post(reportsEndPoints.newfuelAverage(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        }
        else if (state.reportType === 'newfuelActivity') {
          const response = await axios.post(reportsEndPoints.newfuelActivity(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        }
        else if (state.reportType === 'alarmReport') {
          const response = await axios.post(reportsEndPoints.alarmReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        }
        else if (state.reportType === 'helmetStatusReport') {
          const response = await axios.post(reportsEndPoints.helmetStatusReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        }
        else if (state.reportType === 'idleReport') {
          const response = await axios.post(reportsEndPoints.idleReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        }
         else if (state.reportType === 'NRReport') {
          let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
          const response = await axios.get(reportsEndPoints.NRReport(companyInfo.groupId));
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } 
         else if (state.reportType === 'vehicleTravelReport') {
          let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
          const response = await axios.post(reportsEndPoints.vehicleTravelReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } 
        else if (state.reportType === 'vehicleTravelReportGraph') {
          let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
          const response = await axios.post(reportsEndPoints.vehicleTravelReportGraph(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } 
         else if (state.reportType === 'vehiclesStatus') {
          let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
          const response = await axios.get(reportsEndPoints.vehiclesStatus(companyInfo.groupId));
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } 
        else if (state.reportType === 'overSpeeding') {
          const response = await axios.post(reportsEndPoints.overSpeedingReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } else if (state.reportType === 'drivingScore') {
          const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
          const response = await axios.post(reportsEndPoints.drivingScoreReport(companyInfo.companyId),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } else if (state.reportType === 'vehicledrivingScore') {
          const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
          const response = await axios.post(reportsEndPoints.vehicleDrivingScoreReport(companyInfo.companyId), state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } 
        else if (state.reportType === 'drivingScoreGraph') {
          const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
          const response = await axios.post(reportsEndPoints.drivingScoreGraph(companyInfo.companyId),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } 
        else if (state.reportType === 'stopageReport') {
          const response = await axios.post(reportsEndPoints.stoppageReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } else if (state.reportType === 'vehicleReport') {
          const response = await axios.post(reportsEndPoints.vehicleTrackingReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        }  else if (state.reportType === 'vehicleReportTest') {
          let url = reportsEndPoints.vehicleTrackingTestReport();
          const response = await axios.post(url,state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } else if (state.reportType === 'bycetrackingreport') {
          let url = reportsEndPoints.bycetrackingreport();
          const response = await axios.post(url,state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } else if (state.reportType === 'DLIReport') {
          const response = await axios.post(reportsEndPoints.dliReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        }  else if (state.reportType === 'OIL_CHANGE') {
          const response = await axios.post(reportsEndPoints.maintenanceReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } 
        else if (state.reportType === 'TYRE_CHANGE') {
          const response = await axios.post(reportsEndPoints.maintenanceReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } 
        else if (state.reportType === 'otherMaintainance') {
          const response = await axios.post(reportsEndPoints.otherMaintenanceReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } 
        else if (state.reportType === 'distanceTraveled') {
          const response = await axios.post(reportsEndPoints.distanceTraveledReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } else if (state.reportType === 'fuelAverage') {
          const response = await axios.post(reportsEndPoints.fuelAverageReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } else if (state.reportType === 'routefenceReport') {
          const response = await axios.post(reportsEndPoints.routefenceReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } 
        else if (state.reportType === 'performanceReport') {
          const response = await axios.post(reportsEndPoints.performanceReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } 
        else if (state.reportType === 'rescueCellsite') {
          window.open(
            reportsEndPoints.rescueCellsite(state),
            '_blank'
          )
        }
        else if (state.reportType === 'vehiclesGeoFenceReport') {
          const response = await axios.post(reportsEndPoints.vehiclesGeoFenceReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        }
        else if (state.reportType === 'vehiclesRouteFenceReport') {
          const response = await axios.post(reportsEndPoints.vehiclesRouteFenceReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        }
        else if (state.reportType === 'rosterLog') {
          window.open(
            reportsEndPoints.rosterLog(state),
            '_blank'
          )
         
        }
        else if (state.reportType === 'geoFenceFuelReport') {
          const response = await axios.post(reportsEndPoints.geoFenceFuelReport(), state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        }
        else if (state.reportType === 'pjpReport') {
          const response = await axios.get(reportsEndPoints.pjpReport(state));
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        }
        else if (state.reportType === 'violationReportBATCustom') {
          const response = await axios.post(reportsEndPoints.violationReportCustom(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        }
        else if (state.reportType  === "violationVisualReport") {
          window.open(
            reportsEndPoints.violationVisualReport(state),
            '_blank'
          )
        } else if(state.reportType === "fuelVisualReportNew"){
          window.open(
            reportsEndPoints.fuelVisualReportNew(state),
            '_blank'
          )
        } else if(state.reportType === "fuelVisualReport"){
          window.open(
            reportsEndPoints.fuelVisualReport(state),
            '_blank'
          )
        } else if(state.reportType === 'vehicleHistoryReport'){
          const response = await axios.post(reportsEndPoints.vehicleHistoryReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        } else if(state.reportType === 'geoFenceTourReport'){
          const response = await axios.post(reportsEndPoints.geoFenceTourReport(),state);
          if(response.data.status === "success") {
            dispatch({type: "MODAL"});
          }
        }


        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
      } catch (e) {
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
        throw e;
      }
    },
    mileageReportView : (state) => async (dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) dispatch(authenticationActions.logoutUser());
      try {
        dispatch({ type: duck.types.IS_LOADING, isLoading: true });
          const response = await axios.post(reportsEndPoints.mileageReportView(),state);

          let rowsResponse = response.data.dataset;
          rowsResponse['sizePerPage'] = state['sizePerPage'];
          rowsResponse['page'] = state['page'];
          rowsResponse['state'] = state;

          dispatch({type: "MILEAGE_REPORT", payload: rowsResponse});
          dispatch({ type: duck.types.IS_LOADING, isLoading: false });
          return response.data;
      } catch (e) {
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
        throw e;
      }
    },
    reportsView : (state) => async (dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) dispatch(authenticationActions.logoutUser());

      try {
        dispatch({ type: duck.types.IS_LOADING, isLoading: true });
          const response = await axios.post(reportsEndPoints.geofenceSummaryReportView(),state);
          // const response = await axios.get("http://localhost:8080/api/v1/dev/geoFenceSummaryReportViewFromDev");

          let rowsResponse = response.data.dataset;
          rowsResponse['sizePerPage'] = state['sizePerPage'];
          rowsResponse['page'] = state['page'];
          rowsResponse['state'] = state;

          dispatch({type: "GEO_FENCE_SUMMARY", payload: rowsResponse});
          dispatch({ type: duck.types.IS_LOADING, isLoading: false });
          return response.data;
      } catch (e) {
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
        throw e;
      }

    },
    fuelReportView : (state) => async (dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) dispatch(authenticationActions.logoutUser());

      try {
        dispatch({ type: duck.types.IS_LOADING, isLoading: true });

          const response = await axios.post(reportsEndPoints.fuelReportView(),state);
          let rowsResponse = response.data.dataset;
          rowsResponse['sizePerPage'] = state['sizePerPage'];
          rowsResponse['page'] = state['page'];
          rowsResponse['state'] = state;

          dispatch({type: "FUEL_REPORT_VIEW", payload: rowsResponse});
          dispatch({ type: duck.types.IS_LOADING, isLoading: false });
          return response.data;
      } catch (e) {
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
        throw e;
      }

    },
    fuelActivityReportView : (state) => async (dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) dispatch(authenticationActions.logoutUser());

      try {
        dispatch({ type: duck.types.IS_LOADING, isLoading: true });

          const response = await axios.post(reportsEndPoints.fuelActivityReportView(),state);
          let rowsResponse = response.data.dataset;
          rowsResponse['sizePerPage'] = state['sizePerPage'];
          rowsResponse['page'] = state['page'];
          rowsResponse['state'] = state;

          dispatch({type: "FUEL_ACTIVITY_REPORT_VIEW", payload: rowsResponse});
          dispatch({ type: duck.types.IS_LOADING, isLoading: false });
          return response.data;
      } catch (e) {
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
        throw e;
      }

    },
    reportsMapView : (state) => async (dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) dispatch(authenticationActions.logoutUser());

      try {
        dispatch({ type: duck.types.IS_LOADING, isLoading: true });

          const response = await axios.post(reportsEndPoints.geofenceSummaryReportsMapView(),state);
          console.log("----GEO_FENCE_SUMMARY_MAP_VIEW----response-----",response);

          dispatch({type: "GEO_FENCE_SUMMARY_MAP_VIEW", payload: response.data.dataset});
          dispatch({ type: duck.types.IS_LOADING, isLoading: false });
          return response.data;
      } catch (e) {
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
        throw e;
      }

    },
    exportObservationsReport: (thingName, data) => async (dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) dispatch(authenticationActions.logoutUser());
      try {
      dispatch({ type: duck.types.IS_LOADING, isLoading: true });
      const response = await axios.post(reportsEndPoints.activityReport(thingName,data),{});
      if(response.data.status === "success") {
        dispatch({type: "MODAL"});
      }
      dispatch({ type: duck.types.IS_LOADING, isLoading: false });
    } catch (e) {
      dispatch({ type: duck.types.IS_LOADING, isLoading: false });
      throw e;
    }
    },
    fetchLogs: (state) => async(dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) dispatch(authenticationActions.logoutUser());
      const response = await axios.get(`${reportsEndPoints.fetchLogs()}`);
      dispatch({type: "LOGS", payload: response?.data?.data})
    },
    toggleModal: (state) => (dispatch, getState) => {
      if (moment().isBefore(moment(getState().userAuthentication.user.exp))) dispatch(authenticationActions.logoutUser());
      dispatch({type: "MODAL"})
    }

  })
})
