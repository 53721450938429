import React, {  useState , useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';
import ListMap from './listMap';
import { DateRangePicker } from 'react-date-range';
import calenderIcon from '../../../assets/img/calender.png';
import { subDays } from 'date-fns';
import { Popover, PopoverBody } from 'reactstrap';
import Select from "react-select-nested-group";
import TelcoSite from '../../../ducks/TelcoSite';
import moment from 'moment';
import { map } from "lodash";
import toastr from "toastr";
import '../index.css';

const TelcoList = (props) => {

    const [center] = useState([73.13915736973286, 33.59492064226822])
    const [popoverOpen, setPopoverOpen] = React.useState(false)
    const [searchValue, setSearchValue] = React.useState([])
    const [searchVehicleValues, setSearchVehicleValues] = React.useState([])
    const [vehiclePath , setvehiclePath] = useState([])
    const [tourData , setTourData] = useState()

    const [dateRangePicker, setDateRange] = React.useState({
        selection: {
            startDate: subDays(new Date(), 7),
            endDate: new Date(),
            key: 'selection',
        },
    },)

    
    const fetchOptions = async () => {
        await props.action.getThings();
    }

    const fetchSites = async () => {
        await props.action.getTelcoSites()
    }

    const handleRangeChange = async (payload) => {
        setDateRange({ ...payload })
      }

    const fetchVehiclePath = async () => {

        const start = dateRangePicker.selection.startDate;
        const end = dateRangePicker.selection.endDate;
        // const difference = moment(start).diff(moment(end), 'minutes');
        let interval;
        if (moment(start).isSame(moment(end), 'day')) {
            // If they are the same, set the end date to be 24 hours later
            const adjustedEnd = moment(end).add(24, 'hours').toISOString();
            interval = { 
                "startDate": moment(start).format(), 
                "endDate": moment(adjustedEnd).format() 
            };
        }
        else{
            interval = { "startDate": moment(start).format(), "endDate": moment(end).format() };
        }
        

        // this.setState({ ...this.state, mapLoading: true });

        const path = await props.action.getVehiclePath(JSON.stringify({ interval }), searchValue.value) || {};
        if (path.length === 0){
          toastr.error("No distance covered during selected time frame")
        }
        let data = {
            interval:JSON.stringify({interval}),
            id:searchValue.value,
            geofenceIds: []
        }
        setvehiclePath(path)
        const siteTour = await props.action.getTelcoSiteTour(data)
        setTourData(siteTour)
    }


    const toggle = (e) => {
        setPopoverOpen(!popoverOpen)
    };
    useEffect(() => {
        fetchOptions();
        fetchSites();
    }, []);

    useEffect(() => {
        setSearchVehicleValues(map(props?.things, (item) => ({
            value: item._id,
            label: item.plateNumber || item.imei.substr(-8),
            item: item,
        })))
    }, [props.things]);

    const filterVehicle = async (value) => {
        setSearchValue({ ...value });
      };

     const navigateToCreateGeoFence = () => {
        props.history.push('/addTelcoSite');
      }

    return (
        <>
            <div className='row m-b-10'>

                <div className="col-md-3">
                    <Select
                        name="form-field-name"
                        isSearchable={true}
                        placeholder="Search Vehicle"
                        onChange={filterVehicle}
                        value={searchValue}
                        options={searchVehicleValues}
                    />
                </div>

                <div className="col-md-3">
                    <div className="float-right">
                        <a onClick={toggle} id="Popover1">
                            <img className="rag-picker" src={calenderIcon} alt="" />
                        </a>
                        <Popover placement="bottom" isOpen={popoverOpen} toggle={toggle} target="Popover1">
                            <PopoverBody>
                                <div>
                                    <DateRangePicker
                                        onChange={handleRangeChange}
                                        showSelectionPreview={false}
                                        moveRangeOnFirstSelection={false}
                                        className={'PreviewArea'}
                                        months={1}
                                        ranges={[dateRangePicker.selection]}
                                        direction="horizontal"
                                        maxDate={new Date()}
                                    />
                                </div>
                            </PopoverBody>
                        </Popover>
                        <button onClick={fetchVehiclePath} className='btn rag-primary-btn rag-score-filter-btn' >Filter
                        </button>
                    </div>
                </div>
                <div className='col-md-6'>
                    <button onClick={navigateToCreateGeoFence} className='btn rag-primary-btn rag-score-filter-btn' >Add Site
                    </button>
                </div>
            </div>
            <ListMap
                center={{
                    lat: center[1],
                    lng: center[0],
                }}
                telcoSites={props.sites}
                startCord={vehiclePath.length > 1 ? vehiclePath[0] : null}
                endCord={vehiclePath.length > 1 ? vehiclePath[vehiclePath.length - 1] : null}
                path={vehiclePath.length > 1 ? vehiclePath : null}
                tourData={tourData}
                radius={200}
            />

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        things: state.telcosites.things,
        sites: state.telcosites.sites
    };
};

const mapDispatchToProps = (dispatch) => ({
    action: bindActionCreators(TelcoSite.creators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TelcoList)

